"use client";

import { usePathname, useRouter } from "next/navigation";
import { JSX, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Modal from "rivals/components/shared/Modal";
import PushNotificationsBanner from "rivals/components/shared/PushNotificationsBanner/index";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import useGetSiteFromLocation from "rivals/services/getSiteFromLocation/useGetSiteFromLocation";
import {
  DISMISSED_DURATION_DAYS,
  DISMISSED_ENABLE_NOTIFICATION_KEY,
  MS_PER_DAY,
  NATIONAL_SUBDOMAIN,
  PATHS
} from "rivals/shared/constants";
import useNotificationManager from "rivals/shared/hooks/useNotificationManager";
import { NotificationPermissionStatus } from "rivals/shared/hooks/useNotificationManager/types";
import usePWAManager from "rivals/shared/hooks/usePWAManager";
import {
  isNationalSite,
  isNationalUmbrellaSite
} from "rivals/shared/utils/nationals";
import styles from "./PushNotificationsBanner.module.scss";

export default function PushNotificationsBannerWrapper(): JSX.Element | null {
  const pathname = usePathname();
  const router = useRouter();
  const onNotificationsPage = pathname === PATHS.NOTIFICATIONS;
  const [isOutsideDismissedWindow, setIsOutsideDismissedWindow] = useState(
    false
  );
  const { isPWA } = usePWAManager();
  const { data } = useGetCurrentUser();
  const { data: currentSite } = useGetSiteFromLocation();
  const invertButton = !isNationalUmbrellaSite(currentSite?.subdomain ?? null);
  const authenticated = !!data?.user;
  const primarySite = data?.user?.primarySite;
  const isPrimarySite =
    !!currentSite &&
    !!primarySite &&
    currentSite.subdomain === primarySite.subdomain;

  const {
    isFeatureEnabled,
    requestNotificationPermission,
    notificationPermissionStatus
  } = useNotificationManager();

  const showInPWA =
    isMobile &&
    isPWA &&
    isOutsideDismissedWindow &&
    notificationPermissionStatus === NotificationPermissionStatus.DEFAULT &&
    isPrimarySite;
  const showInDesktop =
    !isMobile &&
    !isPWA &&
    authenticated &&
    !onNotificationsPage &&
    isOutsideDismissedWindow &&
    isFeatureEnabled &&
    notificationPermissionStatus === NotificationPermissionStatus.DEFAULT;

  useEffect(() => {
    const dismissed = localStorage.getItem(DISMISSED_ENABLE_NOTIFICATION_KEY);
    if (!dismissed) {
      setIsOutsideDismissedWindow(true);
      return;
    }

    const lastDismissed = new Date(parseInt(dismissed));
    const daysSinceDismiss =
      (Date.now() - lastDismissed.getTime()) / MS_PER_DAY;

    if (daysSinceDismiss > DISMISSED_DURATION_DAYS) {
      setIsOutsideDismissedWindow(true);
    }
  }, []);

  function handleDismiss(): void {
    localStorage.setItem(
      DISMISSED_ENABLE_NOTIFICATION_KEY,
      Date.now().toString()
    );
    setIsOutsideDismissedWindow(false);
  }

  async function handleConfirm(): Promise<void> {
    if (isPrimarySite || showInPWA) {
      await requestNotificationPermission();
      setIsOutsideDismissedWindow(false);
    } else if (currentSite && primarySite) {
      const subdomain = isNationalSite(currentSite.subdomain)
        ? NATIONAL_SUBDOMAIN
        : currentSite.subdomain;
      router.push(
        `${currentSite.baseUrl.replace(subdomain, primarySite.subdomain)}${
          PATHS.NOTIFICATIONS
        }`
      );
    }
  }

  if (showInPWA) {
    return (
      <Modal
        handleCancel={handleDismiss}
        open={showInPWA}
        showFooter={false}
        width={"fit-content"}
        wrapClassName={styles.modalContainer}
      >
        <PushNotificationsBanner
          inPWA={isPWA}
          invertedButton
          onClick={handleConfirm}
          onDismiss={handleDismiss}
        />
      </Modal>
    );
  } else if (showInDesktop) {
    return (
      <PushNotificationsBanner
        inPWA={false}
        invertedButton={invertButton}
        onClick={handleConfirm}
        onDismiss={handleDismiss}
      />
    );
  }
  return null;
}

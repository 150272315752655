"use client";

import { usePathname, useRouter } from "next/navigation";
import { JSX, useEffect, useState } from "react";
import { isAndroid, isMobile } from "react-device-detect";
import useGetCurrentUser from "rivals/services/getCurrentUser/useGetCurrentUser";
import useGetSiteFromLocation from "rivals/services/getSiteFromLocation/useGetSiteFromLocation";
import {
  DISMISSED_DURATION_DAYS,
  DISMISSED_INSTALL_PWA_KEY,
  MS_PER_DAY,
  PATHS
} from "rivals/shared/constants";
import usePWAManager from "rivals/shared/hooks/usePWAManager";
import { isNationalUmbrellaSite } from "rivals/shared/utils/nationals";
import { ContainerProps } from "./types";
import InstallPWA from ".";

export default function InstallPWAContainer({
  asBanner = true
}: ContainerProps): JSX.Element | null {
  const router = useRouter();
  const pathname = usePathname();
  const { data: currentSite } = useGetSiteFromLocation();
  const invertButton = !isNationalUmbrellaSite(currentSite?.subdomain ?? null);
  const onNotificationsPage = pathname === PATHS.NOTIFICATIONS;
  const [isOutsideDismissedWindow, setIsOutsideDismissedWindow] = useState(
    false
  );
  const { isPWA } = usePWAManager();
  const { data } = useGetCurrentUser();
  const authenticated = !!data?.user;
  const user = data?.user;
  const showInstallPWABanner =
    !isPWA &&
    isMobile &&
    authenticated &&
    !onNotificationsPage &&
    isOutsideDismissedWindow &&
    asBanner &&
    user?.primarySite?.enablePwa;
  const siteShortName = user?.primarySite?.shortName?.toLowerCase();

  const navigateToInstallGuide = (): void => {
    // Only set dismissed date when displayed as a banner with close icon
    if (asBanner) {
      localStorage.setItem(DISMISSED_INSTALL_PWA_KEY, new Date().toString());
    }

    if (!siteShortName) {
      // this should be a rare case
      return;
    }

    const deviceType = isAndroid ? "android" : "ios";
    const installGuideUrl = `https://${siteShortName}.rivals.com/news/how-to-install-the-rivals-app-on-an-${deviceType}-device`;
    router.push(installGuideUrl);
  };

  const dismiss = (): void => {
    localStorage.setItem(DISMISSED_INSTALL_PWA_KEY, new Date().toString());
    setIsOutsideDismissedWindow(false);
  };

  useEffect(() => {
    const dismissDate = localStorage.getItem(DISMISSED_INSTALL_PWA_KEY);
    const dismissedDateGreaterThan45Days =
      dismissDate &&
      new Date().getTime() - new Date(dismissDate).getTime() >
        DISMISSED_DURATION_DAYS * MS_PER_DAY;

    setIsOutsideDismissedWindow(
      !!(!dismissDate || dismissedDateGreaterThan45Days)
    );
  }, []);

  if (showInstallPWABanner) {
    // When displayed as a banner at the bottom of the screen
    return (
      <InstallPWA
        invertedButton={invertButton}
        onClick={navigateToInstallGuide}
        onDismiss={dismiss}
        positionFixed={true}
      />
    );
  } else if (!asBanner) {
    // When displayed as an inline element on the Notifications page
    return (
      <InstallPWA
        invertedButton={true}
        onClick={navigateToInstallGuide}
        positionFixed={false}
        showClose={false}
      />
    );
  }
  return null;
}
